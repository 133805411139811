import {
  Field,
  LinkField,
  ImageField,
  withDatasourceCheck,
  isEditorActive,
  RichText,
  Text,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { BrightcoveVideoProps } from '../../components/video/brightcove-video-props';
import { ReferenceStringItem } from 'components/foundation/common/reference-string/reference-string-props';
import NextLink from 'components/foundation/non-sitecore/NextLink';
import NextImage from 'components/foundation/non-sitecore/NextImage';
import BaseVideoModal from 'components/video/BaseVideoModal';
import { YoutubeVideoProps } from 'components/video/youtube-video-props';
import ResultImage from 'components/atomic-components/ResultImage';

export type SimplifiedHeroBannerProps = ComponentProps & {
  fields: {
    heading: Field<string>;
    text: Field<string>;
    description: Field<string>;
    ctaButton: LinkField;
    ctaSecondaryButton: LinkField;
    imageCreditName: Field<string>;
    imageCreditTitle: Field<string>;
    imageCreditDescription: Field<string>;
    headingColor: ReferenceStringItem;
    ctaColor: ReferenceStringItem;
    heroImage: ImageField;
    productLogo: ImageField;
    productIcon: {
      fields: {
        productIcon: ImageField;
      };
    };
    destinationLink: LinkField;
    isCtaAndDestinationBothLinksFilled: boolean;
    ctaButtonLabel?: Field<string>;
    videos?: (BrightcoveVideoProps | YoutubeVideoProps)[];
    noFollow?: Field<boolean>;
    secondaryNoFollow?: Field<boolean>;
    contentDirection: {
      name: string;
    };
    isGradientRequired?: Field<boolean>;
    byPassNextJsImageOptimization?: Field<boolean>;
    heroImageCDNUrl?: Field<string>;
    videoOnFullBannerClick?: Field<boolean>;
  };
};

const SimplifiedHeroBanner = (props: SimplifiedHeroBannerProps): JSX.Element => {
  const editorActive = isEditorActive();
  let headingColor = 'purple';
  let ctaColor = 'purple';
  const video = props.fields?.videos?.[0];
  const videoOnFullBannerClick = props?.fields?.videoOnFullBannerClick?.value;
  const textDirection = props?.fields?.contentDirection?.name;
  const directionClass =
    textDirection == 'Right'
      ? '[&>.banner-content]:lg:order-last [&>div>.hero-banner-gradient]:lg:right-0  '
      : '[&>.banner-content]:lg:order-first ';

  const ctaVideoButton = props.fields.ctaButtonLabel && video;

  const isCtaAndDestinationBothLinksFilled =
    props.fields?.destinationLink &&
    props.fields?.destinationLink?.value?.href !== '' &&
    props.fields?.ctaButton &&
    props.fields?.ctaButton?.value?.href !== ''
      ? true
      : false;
  const isDestinationLinkFilled =
    props.fields?.destinationLink && props.fields?.destinationLink?.value?.href !== ''
      ? true
      : false;

  if (props.fields?.headingColor) {
    headingColor = props.fields.headingColor?.fields?.value?.value;
  }

  if (props.fields?.ctaColor) {
    ctaColor = props.fields.ctaColor?.fields?.value?.value;
  }

  const ctaColorVariants = {
    green: 'btn-primary-dark',
    purple: 'btn-feature',
    teal: 'btn-primary',
  };

  const headingColorVariants = {
    green: 'text-avidgreen',
    purple: 'text-avidpurple',
    teal: 'text-teal',
    black: 'text-black',
    white: 'text-white',
  };

  const bannerMarkup = (): JSX.Element => (
    <div
      className={`grid grid-cols-12 richTextBl mb-10 lg:mb-20  text-base-darker ${directionClass}`}
    >
      <div className="col-span-12 lg:min-h-250 w-full aspect-video lg:h-auto lg:col-span-8 xl:col-span-7 relative">
        {!editorActive && props.fields.byPassNextJsImageOptimization?.value ? (
          <span className="box-border max-w-full block absolute object-cover min-w-full h-full">
            {props.fields.heroImageCDNUrl?.value ? (
              <ResultImage
                src={props.fields.heroImageCDNUrl?.value ?? ''}
                alt={props.fields.heroImage?.value?.alt as string}
                className="min-w-full min-h-full col-span-12  w-full aspect-video lg:col-span-8 xl:col-span-7 object-cover"
              />
            ) : (
              props.fields.heroImage?.value?.src != null &&
              props.fields.heroImage?.value?.src != '' && (
                <ResultImage
                  src={props.fields.heroImage?.value?.src ?? ''}
                  alt={props.fields.heroImage?.value?.alt as string}
                  className="min-w-full min-h-full col-span-12  w-full aspect-[16/9] lg:col-span-8 xl:col-span-7 object-cover"
                />
              )
            )}
          </span>
        ) : (
          <NextImage
            field={props.fields.heroImage}
            className="col-span-12 min-h-250  w-full aspect-video lg:col-span-8 xl:col-span-7 object-cover"
            layout="fill"
            priority={true}
          />
        )}

        {props.fields.isGradientRequired?.value && (
          <div
            className={`hidden hero-banner-gradient absolute  lg:block lg:h-[100%]  lg:w-25p ${
              textDirection == 'Right' ? 'reverse' : ''
            }`}
          ></div>
        )}
        <div className="absolute text-right right-8 bottom-2 lg:bottom-8">
          {(props.fields.imageCreditName?.value || editorActive) && (
            <Text
              tag="p"
              field={props.fields.imageCreditName}
              className="uppercase mt-8  text-shadow-md tracking-widest-plus text-white font-avidwalsheimbold font-bold text-13 leading-17 lg:text-18 lg:leading-6.75"
            />
          )}
          {(props.fields.imageCreditTitle?.value || editorActive) && (
            <Text
              tag="p"
              field={props.fields.imageCreditTitle}
              className="uppercase  text-shadow-md text-white tracking-widest  text-13 leading-17 lg:text-base lg:leading-6"
            />
          )}
          {(props.fields.imageCreditDescription?.value || editorActive) && (
            <Text
              tag="p"
              field={props.fields.imageCreditDescription}
              className=" text-shadow-md italic text-white mr-5px font-avidwalsheimregularoblique font-normal  text-12 leading-18 lg:text-14 lg:leading-21"
            />
          )}
        </div>
      </div>
      <div className="p-6 banner-content bg-neutral-darkest col-span-12 h-auto bg-no-repeat lg:p-0 lg:col-span-4 xl:col-span-5 lg:order-first relative">
        {((props.fields.productIcon && props.fields.productIcon.fields.productIcon.value?.src) ||
          editorActive) && (
          <div
            className="hidden lg:block bg-left5000 h-75p bg-no-repeat lg:bg-leftTop40"
            style={{
              backgroundImage: props.fields.productIcon
                ? `url(${props.fields.productIcon.fields.productIcon.value?.src})`
                : 'none',
            }}
          ></div>
        )}
        <div className="text-center lg:text-left relative lg:absolute lg:top-[50%] lg:translate-y-[-50%] lg:px-8 2xl:pl-24 2xl:pr-8">
          {(props.fields.productLogo?.value || editorActive) && (
            <div className="">
              <NextImage
                field={props.fields.productLogo}
                className={` m-auto lg:m-0  h-90 w-auto max-w-full`}
                priority={true}
              />
            </div>
          )}
          <Text
            tag="h1"
            field={props.fields.heading}
            className={`${
              headingColorVariants[headingColor as keyof typeof ctaColorVariants]
            } text-32 2xl:text-48 leading-37  xl:leading-54 normal-case`}
          />
          <RichText
            field={props.fields.description}
            tag="p"
            className="rte text-white text-21 leading-26 2xl:text-28 2xl:leading-9 mt-2 lg:mt-4"
          />
          <>
            {props.fields.isCtaAndDestinationBothLinksFilled && !editorActive ? (
              <div
                className={`${
                  ctaColorVariants[ctaColor as keyof typeof ctaColorVariants]
                } inline-block mt-6 lg:mt-8 md:w-fit`}
              >
                {props.fields.ctaButton.value.text}
              </div>
            ) : !ctaVideoButton ? (
              <>
                <NextLink
                  className={`${
                    ctaColorVariants[ctaColor as keyof typeof ctaColorVariants]
                  } block m-auto w-fit  lg:inline-block mt-6 lg:mt-8 `}
                  field={props.fields.ctaButton}
                  linkCustomizations={{ noFollow: props.fields.noFollow }}
                />
                <NextLink
                  className={`btn-outline-dark block m-auto w-fit  lg:inline-block mt-6 lg:ml-5 lg:mt-8 h-auto py-6.5`}
                  field={props.fields.ctaSecondaryButton}
                  linkCustomizations={{ noFollow: props.fields.noFollow }}
                />
              </>
            ) : videoOnFullBannerClick ? (
              <>
                <a
                  href="#"
                  className={`${
                    ctaColorVariants[ctaColor as keyof typeof ctaColorVariants]
                  } block m-auto w-fit  lg:inline-block mt-6 lg:mt-8 `}
                >
                  {props.fields.ctaButtonLabel?.value}
                </a>
              </>
            ) : (
              <>
                <BaseVideoModal
                  video={video}
                  openCtaChildren={
                    <a
                      href="#"
                      className={`${
                        ctaColorVariants[ctaColor as keyof typeof ctaColorVariants]
                      } block m-auto w-fit  lg:inline-block mt-6 lg:mt-8 `}
                    >
                      {props.fields.ctaButtonLabel?.value}
                    </a>
                  }
                />
                <NextLink
                  className={`btn-outline-dark block m-auto w-fit  lg:inline-block mt-6 lg:ml-5 lg:mt-8`}
                  field={props.fields.ctaSecondaryButton}
                  linkCustomizations={{ noFollow: props.fields.noFollow }}
                />
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );

  const bannerContent = bannerMarkup();

  if (ctaVideoButton && videoOnFullBannerClick) {
    return (
      <BaseVideoModal video={video} openCtaClassName="w-full" openCtaChildren={bannerContent} />
    );
  } else {
    return isCtaAndDestinationBothLinksFilled ? (
      <NextLink field={props.fields.ctaButton} className="min-h-250 w-[100%]" hideText>
        {bannerContent}
      </NextLink>
    ) : isDestinationLinkFilled ? (
      <NextLink field={props.fields.destinationLink} className="min-h-250 w-[100%]" hideText>
        {bannerContent}
      </NextLink>
    ) : (
      bannerContent
    );
  }
};

export default withDatasourceCheck()<SimplifiedHeroBannerProps>(SimplifiedHeroBanner);
