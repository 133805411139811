import { buildSearchEngine } from '@coveo/atomic-react';
import { useEffect, useMemo, useState } from 'react';
import { pushCoveoSearchEvent } from 'lib/google-analytics/coveo-site-search';
import { Placeholder, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { TaxonomyTermItem } from '../../components/category-listing-search/product-category-listing-models';
import dynamic from 'next/dynamic';
import { useI18n } from 'next-localization';
import { getLanguageName } from 'lib/navigation/navigation-utils';
import { useAppDispatch } from 'lib/store/hooks';
import { PriceQueue } from 'lib/commerce/product/price-queue';
import { fetchCartItems } from 'components/cart/cart-slice';
import { useSession } from 'next-auth/react';
import { getBearerToken } from 'lib/authentication/account-provider';

const BaseSearchResults = dynamic(() => import('../../components/search/BaseSearchResults'));

type CustomSearchProps = ComponentProps & {
  fields: {
    contentType: {
      fields: TaxonomyTermItem;
    };
    labels: { value: string };
  };
};

const Default = (props: CustomSearchProps): JSX.Element => {
  const hasContentTypeFilter = props.fields?.contentType?.fields != null;
  const [renderIt, setRenderIt] = useState(false);
  const [priceQueue, setPriceQueue] = useState<PriceQueue | null>(null);
  const { locale } = useI18n();
  const { data: session } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setTimeout(() => {
      setPriceQueue(new PriceQueue());
      setRenderIt(true);
    }, 100);
  }, []);

  const searchEngine = useMemo(() => {
    return buildSearchEngine({
      configuration: {
        accessToken:
          process.env.COVEO_ACCESS_TOKEN && process.env.COVEO_ACCESS_TOKEN.length > 0
            ? process.env.COVEO_ACCESS_TOKEN
            : '',
        organizationId:
          process.env.COVEO_ORGANIZATION && process.env.COVEO_ORGANIZATION.length > 0
            ? process.env.COVEO_ORGANIZATION
            : '',
        search: {
          pipeline:
            process.env.COVEO_PIPELINE && process.env.COVEO_PIPELINE.length > 0
              ? process.env.COVEO_PIPELINE
              : '',
          searchHub: 'AvidComGlobalSearch',
          preprocessSearchResponseMiddleware: async (response) => {
            const cartItemData = await dispatch(
              fetchCartItems({ bearerToken: bearerToken })
            ).unwrap();
            const cartItemsIds = cartItemData.data.cartItems.map(
              (item): string => item.cartItem?.productId
            );
            response.body.results.forEach((result) => {
              if (result.raw.objecttype !== 'Product' && result.raw.objecttype !== 'ParentProduct')
                return;
              const prId = result.raw?.avid_productid as string;
              result.raw.objecttype === 'Product' &&
                (result.raw.isInCart = cartItemsIds?.includes(prId ?? ''));
              result.raw.currentCurrencyIsoCode = cartItemData.data.cartSummary.currencyIsoCode;
            });
            return response;
          },
        },
        analytics: {
          analyticsClientMiddleware: (...[, payload]) => {
            pushCoveoSearchEvent(payload);
            return payload;
          },
        },
        preprocessRequest: (request, clientOrigin) => {
          if (!hasContentTypeFilter) {
            return request;
          }

          const language = locale();
          if (language == null || language.length === 0) {
            return request;
          }

          if (clientOrigin == 'searchApiFetch') {
            const body = JSON.parse(request.body?.toString() ?? '');
            body.aq = `@documenttype==(${
              props.fields.contentType.fields.termName.value
            }) AND (@language==(${getLanguageName(language)}) OR (NOT @language))`;
            request.body = JSON.stringify(body);
          }

          return request;
        },
      },
    });
  }, [
    dispatch,
    bearerToken,
    hasContentTypeFilter,
    locale,
    props.fields.contentType.fields.termName.value,
  ]);

  const itemListName = 'Custom Search Results';
  const itemListId = `CustomSearchResults_0`;

  return (
    <>
      {searchEngine && renderIt && (
        <BaseSearchResults
          listId={itemListId}
          listName={itemListName}
          searchEngine={searchEngine}
          labels={props.fields?.labels ?? { value: '' }}
          leftRailBottomConntent={
            <Placeholder name="custom-search-rail-bottom" rendering={props.rendering} />
          }
          priceQueue={priceQueue}
        />
      )}
    </>
  );
};

export default withDatasourceCheck()(Default);
