import { Field, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';

import { ComponentProps } from 'lib/component-props';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import dynamic from 'next/dynamic';

const AtomicSearchInterface = dynamic(
  () => import('@coveo/atomic-react').then((mod) => mod.AtomicSearchInterface),
  { ssr: false }
);

const AtomicSearchBox = dynamic(
  () => import('@coveo/atomic-react').then((mod) => mod.AtomicSearchBox),
  { ssr: false }
);

const AtomicSearchBoxQuerySuggestions = dynamic(
  () => import('@coveo/atomic-react').then((mod) => mod.AtomicSearchBoxQuerySuggestions),
  { ssr: false }
);

type StaticHeroBannerSearchBoxProps = ComponentProps & {
  fields: {
    contentType: {
      fields: {
        value: Field<string>;
      };
    };
  };
};

const styles = `
atomic-search-interface {
  width: 100%;
}

atomic-search-box {
  width: 100%;
}

atomic-search-box::part(wrapper) {
  border-radius: 4px;
}

atomic-search-box::part(input) {
  font-size: 24px;
  line-height: calc(36 / 24 * 1em);
}

atomic-search-box::part(clear-button) {
  aspect-ratio: 1 / 1;
  line-height: 0;
  position: relative;
}

atomic-search-box::part(clear-icon) {
  height: 16px;
  width: 16px;
}

atomic-search-box::part(submit-button) {
  aspect-ratio: 1 / 1;
  background-color: transparent;
  position: relative;
  width: 3.63rem;
}

atomic-search-box::part(submit-icon) {
  color: #AEB1B3;
  height: 24px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
}

atomic-search-box::part(suggestions-wrapper) {
  border-radius: 0;
  box-shadow: 0px 4px 16px 4px rgba(34, 34, 34, 0.16);
  margin-top: 8px;
}

atomic-search-box::part(query-suggestion-icon) {
  display: none;
}

atomic-search-box::part(query-suggestion-text) {
  font-size: 16px;
  line-height: calc(24 / 16 * 1em);
}`;

const Default = (props: StaticHeroBannerSearchBoxProps): JSX.Element => {
  const contentType = props.fields.contentType?.fields?.value?.value;
  const isEditing = isEditorActive();

  if (isEditing && contentType == null) {
    return <div className="text-white">Please select a content type to enable search</div>;
  }

  return (
    <>
      <style>{styles}</style>

      <div className="Static-Hero-Banner-Search-Box container px-5 mx-auto">
        <div className="max-w-500 mx-auto">
          <AtomicSearchInterface>
            <AtomicSearchBox
              redirection-url={`/search#tab=${contentType}`}
              suggestionDelay={900}
              className="search-box"
            >
              <AtomicSearchBoxQuerySuggestions />
            </AtomicSearchBox>
          </AtomicSearchInterface>
        </div>
      </div>
    </>
  );
};

export default withDatasourceCheck()(Default);
