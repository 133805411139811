import { Field, LinkField, RichText, withPlaceholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import NextLink from 'components/foundation/non-sitecore/NextLink';
import { BaseCarouselProps } from 'components/carousel/BaseCarouselProps';
import Carousel from 'components/carousel/Carousel';
import { ComponentProps } from 'lib/component-props';
import { trackWindowScroll } from 'react-lazy-load-image-component';

type CardCarouselProps = ComponentProps &
  BaseCarouselProps & {
    fields: {
      heading: Field<string>;
      link: LinkField;
      noFollow?: Field<boolean>;
      byPassNextJsImageOptimization?: Field<boolean>;
      anchorLinkId: Field<string>;
    };
    name: string;
    cardCarouselItems: JSX.Element[];
    scrollPosition: Record<string, number> | null;
  };

const CardCarousel = (props: CardCarouselProps): JSX.Element => {
  const editorActive = isEditorActive();
  return (
    <div
      className="card-carousel my-10 xl:my-20 sm:my-5 lg:my-10"
      {...(props?.fields?.anchorLinkId?.value ? { id: props?.fields?.anchorLinkId?.value } : {})}
    >
      <RichText
        className="rte text-center h2 uppercase tracking-[0.28rem] xs:mb-6 xl:mb-8 mx-6 lg:mx-8 lg:max-w-carousel xl:mx-auto"
        field={props.fields?.heading}
        tag="h3"
      />
      <div className="max-w-xl xl:max-w-6xl mx-2 sm:mx-auto 2xl:max-w-carousel">
        <div className={editorActive ? 'flex flex-wrap [&>.carousel-item]:w-1/3' : 'hidden'}>
          {props.cardCarouselItems}
        </div>

        {!editorActive && props.params && (
          <Carousel
            MaxItemsPerSlideOnDesktop={props.params.MaxItemsPerSlideOnDesktop}
            MaxItemsPerSlideOnTablet={props.params.MaxItemsPerSlideOnTablet}
            MaxItemsPerSlideOnMobile={props.params.MaxItemsPerSlideOnMobile}
            desktopSize={1199}
          >
            {props.cardCarouselItems.map((el) => {
              const elProps = { ...el.props, scrollPosition: props.scrollPosition };
              return { ...el, props: elProps };
            })}
          </Carousel>
        )}
        {props.fields?.link.value.href && (
          <div className="text-center mt-12 flex justify-center">
            <NextLink
              className={`${props.params.Style === 'feature' ? 'btn-feature' : 'btn-outline'}`}
              linkCustomizations={props.fields}
              field={props.fields?.link}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default trackWindowScroll(
  withPlaceholder({ placeholder: 'card-carousel-items', prop: 'cardCarouselItems' })(CardCarousel)
);
