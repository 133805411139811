export const absoluteMediaUrl = (relativeUrl: string): string => {
  if (relativeUrl?.startsWith('http')) {
    return relativeUrl;
  }
  let serverPart = process.env.LOCAL_SITECORE_API_HOST ?? process.env.SITECORE_API_HOST;
  if (serverPart && serverPart.length > 0 && process.env.NODE_ENV == 'development') {
    return `${serverPart}${relativeUrl}`;
  } else {
    serverPart = process.env.AVID_SITECORE_EDGE_MEDIA_PREFIX;
    const tenantId = process.env.AVID_SITECORE_EDGE_TENANTID;
    return `${serverPart}/${tenantId}${relativeUrl?.replace('~/', '').replace('-/', '')}`;
  }
};
