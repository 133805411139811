import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';

const TwoColumnVerticalContainer = (props: ComponentProps): JSX.Element => {
  return (
    <div className="grid grid-cols-1 px-8 lg:gap-x-8 py-10 lg:py-0 lg:grid-cols-2  lg:mb-20 2xl:px-0 twoColumnVertical">
      <div className="pt-8 lg:pt-0">
        <Placeholder name="jss-left" rendering={props.rendering} />
      </div>
      <div className="pt-8 lg:pt-0">
        <Placeholder name="jss-right" rendering={props.rendering} />
      </div>
    </div>
  );
};

export default TwoColumnVerticalContainer;
