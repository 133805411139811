import { Field, RichText, Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { t14TemplateIdVal } from 'lib/constants';

type RichTextBlockProps = {
  fields: {
    text: Field<string>;
    anchorLinkId: Field<string>;
    defaultMargin: Field<string>;
  };
};

const RichTextBlock = (props: RichTextBlockProps): JSX.Element => {
  const editorActive = isEditorActive();
  const context = useSitecoreContext();
  const defaultMargin =
    props?.fields?.defaultMargin?.value &&
    props?.fields?.defaultMargin?.value != '' &&
    props?.fields?.defaultMargin?.value != undefined
      ? props?.fields?.defaultMargin?.value
      : null;
  const t14TemplateId = t14TemplateIdVal;
  const pageTemplateId = context?.sitecoreContext.route?.templateId;
  const iFrameClass =
    t14TemplateId === pageTemplateId
      ? '[&_iframe]:w-full [&_iframe]:xl:max-w-730 [&_iframe]:aspect-video [&_iframe]:h-auto'
      : '';
  return editorActive ? (
    <div>
      <div className={`max-w-1208 text-left mx-auto border-2 border-dashed border-introgray p-2 `}>
        Specify the margin you require below this RTE component.
        <Text
          field={props?.fields?.defaultMargin}
          tag="div"
          className="overflow-x-hidden relative top-2 inline-block border-2 w-32 ml-1 px-1"
        />
        px.
        <div className="text-12">
          (Default value is 80px). This content wont be visible in live page.
        </div>
      </div>
      <RichText
        field={props?.fields?.text}
        tag="div"
        className={`rte richTextBl max-w-content mx-auto text-base-darker [&_a]:underline [&_h1]:mb-10 [&_h2]:mb-10 [&_h3]:mb-10 [&_h4]:mb-10 [&_h5]:mb-10 [&_h6]:mb-10 [&_p]:mb-10 [&_a]:text-primary p-1 px-6
            ${iFrameClass}`}
        {...(props?.fields?.anchorLinkId?.value ? { id: props?.fields?.anchorLinkId?.value } : {})}
      />
      <div style={{ height: defaultMargin ? defaultMargin + 'px' : 0 + '80px' }}></div>
      <div></div>
    </div>
  ) : (
    <RichText
      field={props?.fields?.text}
      tag="div"
      style={{ marginBottom: defaultMargin ? defaultMargin + 'px' : '' }}
      className={`rte richTextBl max-w-content mx-auto text-base-darker [&_a]:underline [&_h1]:mb-10 [&_h2]:mb-10 [&_h3]:mb-10 [&_h4]:mb-10 [&_h5]:mb-10 [&_h6]:mb-10 [&_p]:mb-10 [&_a]:text-primary p-1 px-6 ${
        defaultMargin ? '' : 'sm:mb-10 md:mb-20'
      }  ${iFrameClass}`}
      {...(props?.fields?.anchorLinkId?.value ? { id: props?.fields?.anchorLinkId?.value } : {})}
    />
  );
};

export default RichTextBlock;
