import dynamic from 'next/dynamic';
import { SearchEngine } from '@coveo/headless';
import { useContext } from 'react';
import CoveoSearchEngineContext from 'lib/coveo/coveo-context';

const AtomicSearchInterface = dynamic(
  () => import('@coveo/atomic-react').then((mod) => mod.AtomicSearchInterface),
  { ssr: false }
);

const AtomicSearchBox = dynamic(
  () => import('@coveo/atomic-react').then((mod) => mod.AtomicSearchBox),
  { ssr: false }
);

const AtomicSearchBoxQuerySuggestions = dynamic(
  () => import('@coveo/atomic-react').then((mod) => mod.AtomicSearchBoxQuerySuggestions),
  { ssr: false }
);

const styles = `
atomic-search-box {
  height: 56px;
}
atomic-search-box::part(wrapper) {
  border-radius: 4px;
}
atomic-search-box::part(input) {
  font-size: 24px;
  line-height: calc(36 / 24 * 1em);
}
atomic-search-box::part(submit-button) {
  aspect-ratio: 1 / 1;
  background-color: transparent;
  position: relative;
  width: 3.63rem;
}
atomic-search-box::part(submit-icon) {
  color: #AEB1B3;
  height: 24px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
}
`;

const CompactSearch = (): JSX.Element => {
  const searchEngine = useContext(CoveoSearchEngineContext) as unknown as SearchEngine;

  return (
    <>
      <style>{styles}</style>
      <div className="py-10 pl-5 pr-4 items-center gap-72 max-w-[830px] mx-auto">
        {searchEngine && (
          <AtomicSearchInterface
            languageAssetsPath="/searchlabels"
            localization-compatibility-version="v4"
            engine={searchEngine}
            analytics={false}
          >
            <AtomicSearchBox
              redirection-url={`/search`}
              className="search-box"
              suggestionDelay={900}
            >
              <AtomicSearchBoxQuerySuggestions />
            </AtomicSearchBox>
          </AtomicSearchInterface>
        )}
      </div>
    </>
  );
};

export default CompactSearch;
